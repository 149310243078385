import React, {useCallback, useState, useEffect} from 'react';
import {Button, ToggleButton, ToggleButtonGroup} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import './CustomPaginator.scss';

export default function CustomPaginator({swiperRef, data}) {
    const [current, setCurrent] = useState(0);
    const handlePrevious = useCallback(() => {
        swiperRef?.slidePrev();
        setCurrent(swiperRef?.activeIndex);
    }, [swiperRef]);
    useEffect(() => {
        swiperRef?.slideTo(0);
        setCurrent(0);
    }, [data]);

    const handleNext = useCallback(() => {
        swiperRef?.slideNext();
        setCurrent(swiperRef?.activeIndex);
    }, [swiperRef]);
    const handleSlideTo = (index) => {
        try {
            swiperRef?.slideTo(index);
        } catch (e) {
            console.log(e);
        }
    };
    return (
        <div className={'paginator'}>
            <Button className={'paginator--controller'} onClick={handlePrevious}>
                <ArrowBackIosIcon sx={{height: 10, width: 10}} />
            </Button>
            <ToggleButtonGroup
                className={'paginator--toggle'}
                value={current}
                exclusive
                onChange={(e) => {
                    setCurrent(+e.target.value);
                    handleSlideTo(+e.target.value);
                }}
            >
                {data.map((value, index) => (
                    <ToggleButton value={index} key={index}>
                        {index + 1}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
            <Button className={'paginator--controller'} onClick={handleNext}>
                <ArrowForwardIosIcon sx={{height: 10}} />
            </Button>
        </div>
    );
}
