import React, {useCallback, useEffect, useLayoutEffect, useState} from 'react';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIosNew';

import 'swiper/css/effect-coverflow';
import './SwiperCommand.scss';

// import required modules
import {Autoplay, EffectCoverflow} from 'swiper';
import PersonCard from '../../Cards/PersonCard/PersonCard';

import Daniyar from '../../../static/images/avatars/daniyar.png';
import Azret from '../../../static/images/avatars/azret.png';
import Bolat from '../../../static/images/avatars/bolat.png';
import Vlad from '../../../static/images/avatars/vlad.png';
import Laura from '../../../static/images/avatars/laura.png';
import Mukhtar from '../../../static/images/avatars/mukhtar.png';
import SwiperAdaptiveTemplate from '../SwipersTemplate';
import {get_team} from '../../../api/FormsApi';

export default function SwiperCommand({team}) {
    const [swiperRef, setSwiperRef] = useState();
    const handlePrevious = useCallback(() => {
        swiperRef?.slidePrev();
    }, [swiperRef]);

    const handleNext = useCallback(() => {
        swiperRef?.slideNext();
    }, [swiperRef]);

    const generalProps = {
        onSwiper: setSwiperRef,
        effect: 'coverflow',
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        slideToClickedSlide: true,
        autoplay: {
            delay: 2500,
            disableOnInteraction: false,
        },
        loop: true,
        modules: [EffectCoverflow, Autoplay],
        className: 'mySwiper',
    };
    const mobileProps = {
        coverflowEffect: {
            rotate: 0,
            stretch: -40,
            depth: 300,
            modifier: 2,
            slideShadows: false,
        },
    };

    const desktopProps = {
        coverflowEffect: {
            rotate: 0,
            stretch: -24,
            depth: 0,
            modifier: 1,
            slideShadows: false,
        },
    };

    return (
        <div className={'swiper-command'}>
            <button onClick={handlePrevious}>
                <ArrowBackIosIcon sx={{height: 24}} />
            </button>
            <SwiperAdaptiveTemplate
                mp={{...generalProps, ...mobileProps}}
                dp={{...generalProps, ...desktopProps}}
                data={team}
                rc={(card) => <PersonCard person={card} />}
            />
            <button onClick={handleNext}>
                <ArrowForwardIosIcon sx={{height: 24}} />
            </button>
        </div>
    );
}
