import React, {useEffect} from 'react';
import {Bar, BarChart, CartesianGrid, LabelList, ResponsiveContainer, XAxis, YAxis} from 'recharts';
import {nanoid} from 'nanoid';

export default function CustomBarChart({data, field, name, color}) {
    function number_formatter(x) {
        if (x >= 1000000) {
            return (x /= 1000000) === +x.toFixed(0) ? x.toFixed(0) + 'M' : x.toFixed(1) + 'M';
        } else if (x >= 1000) {
            return (x /= 1000) === +x.toFixed(0) ? x.toFixed(0) + 'K' : x.toFixed(1) + 'K';
        } else {
            return x;
        }
    }

    let min;
    let max;
    useEffect(() => {
        const values = data.map((d) => d[field]);
        min = Math.min(...values);
        max = Math.max(...values);
    }, [data]);

    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                data={data}
                margin={{
                    top: 16,
                    // right: 42,
                    // left: 42,
                    bottom: 40,
                }}
            >
                <CartesianGrid strokeDasharray="0 3" />
                <XAxis
                    dataKey="name"
                    orientation={'bottom'}
                    axisLine={false}
                    tickLine={false}
                    padding={'gap'}
                    dy={4}
                    style={{
                        fontSize: '14px',
                        fontFamily: 'Montserrat',
                        fontWeight: 400,
                        color: '#0F2C52',
                    }}
                />
                <YAxis hide={true} domain={[0, max + 100]} />
                <Bar
                    dataKey={field}
                    fill={color}
                    background={{
                        fill: 'transparent',
                        radius: 12,
                        width: 24,
                    }}
                    isAnimationActive={true}
                    animationBegin={0}
                    animationDuration={300}
                    animationEasing={'linear'}
                    radius={8}
                    barSize={24}
                    key={nanoid()}
                >
                    <LabelList
                        dataKey={field}
                        position="top"
                        // formatter={number_formatter}
                        style={{
                            fontSize: '12px',
                            fontFamily: 'Montserrat',
                            fontWeight: 600,
                            color: '#0F2C52',
                        }}
                    />
                </Bar>
                <text
                    x="50%"
                    y="95%"
                    dominantBaseline="middle"
                    textAnchor="middle"
                    style={{
                        fontSize: '14px',
                        fontWeight: 600,
                        fontFamily: 'Montserrat',
                        fill: '#0F2C52',
                    }}
                >
                    {name}
                </text>
            </BarChart>
        </ResponsiveContainer>
    );
}
