import Slider from '../../../Components/Switchers/Slider/Slider';
import InfiniteLoop from '../../../Components/InfiniteLoop/InfiniteLoop';
import SwiperCommand from '../../../Components/Swiper/SwiperCommand/SwiperCommand';
import './Content.scss';
import Contacts from './Contacts/Contacts';
import SwiperCompanies from '../../../Components/Swiper/SwiperCompanies/SwiperCompanies';
import ChartsSwitcher from '../../../Components/Switchers/ChartsSwitcher/ChartsSwitcher';
import Footer from '../../../Components/Footer/Footer';
import SwiperFeedback from '../../../Components/Swiper/SwiperFeedback';
import {useMediaQuery} from '@mui/material';
import ServiceMobile from '../../../Components/Switchers/ServiceMobile';
import {get_feedback, get_team} from '../../../api/FormsApi';
import {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";

export default function Content({active}) {
    const [team, setTeam] = useState(null);
    const [feedback, setFeedback] = useState(null);
    const { t, i18n } = useTranslation();
    useEffect(() => {
        async function fetchData() {
            const data = await get_team();
            setTeam(data);

        }
        fetchData();
    }, []);
    useEffect(() => {
        async function fetchData() {
            const data = await get_feedback();
            setFeedback(data);
        }
        fetchData();
        // setTeam(response);
    }, []);
    const isMobile = useMediaQuery(`(max-width: 1000px)`);
    return (
        <section className={`wrapper ${active ? 'wrapper-active' : null}`}>
            <div className={'top'}>
                <div className={'logos-container'}>
                    <InfiniteLoop />
                </div>
                <div style={{maxWidth: 1250, marginTop: 48}}>
                    <ChartsSwitcher />
                </div>

                <div style={{maxWidth: 1000}}>
                    <SwiperCompanies />
                </div>
            </div>

            <div className={'slider-container'}>
                <div className={'anchor'} id={'service'} />
                <h2 className={'font-32 w700'}>
                    {
                        t('services_block.title')
                    }
                </h2>
                {isMobile ? <ServiceMobile /> : <Slider />}
            </div>

            <div className={'swiper-container'}>
                <div className={'anchor'} id={'command'} />
                <h2 className={'font-32 w700'}>
                    {
                        t('our_team')
                    }
                </h2>
                {team ? <SwiperCommand team={team} key={isMobile} /> : null}
            </div>
            <div className={'feedback-container'}>
                {feedback ? <SwiperFeedback data={feedback} key={isMobile} /> : null}
            </div>

            <div className={'contacts-container'}>
                <div className={'anchor'} id={'contacts'} />
                <h2 className={'font-32 w700'}>
                    {
                        t('contact.title_header')
                    }
                </h2>
                <Contacts />
            </div>
            <Footer />
        </section>
    );
}
