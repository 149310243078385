import {useState} from 'react';
import {Accordion, AccordionDetails, AccordionSummary} from '@mui/material';
import MinusCircle from '../../../../static/images/MinusCircle';
import PlusCircle from '../../../../static/images/PlusCircle';

export default function AccordionContainer({data}) {
    const [expanded, setExpanded] = useState([]);

    function handleExpanded(panel) {
        if (expanded.includes(panel)) {
            setExpanded(
                [...expanded].filter((v) => {
                    return v !== panel;
                })
            );
        } else {
            setExpanded([...expanded, panel]);
        }
    }
    return (
        <div style={{width: '100%'}}>
            {data.map((el, index) => (
                <Accordion
                    className={'standard_accordion'}
                    disableGutters
                    expanded={expanded.includes(`panel-${index}`)}
                    onChange={() => {
                        handleExpanded(`panel-${index}`);
                    }}
                    key={el?.title}
                >
                    <AccordionSummary
                        expandIcon={
                            expanded.includes(`panel-${index}`) ? <MinusCircle /> : <PlusCircle />
                        }
                    >
                        <p className={'font-16 w500'}>
                            {el?.title.slice(0, el?.title.indexOf('\n'))}
                            <br />
                            {el?.title.slice(el?.title.indexOf('\n'))}
                        </p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <el.content />
                    </AccordionDetails>
                </Accordion>
            ))}
        </div>
    );
}
