import styles from '../index.module.scss';
import AccordionContainer from '../Components/AccordionContainer';

export default function Layout({data, page}) {
    const first = data[0];
    return (
        <div>
            <div className={styles.first_page}>{first && <first.content />}</div>
            <AccordionContainer data={data.slice(1)}></AccordionContainer>
            {/*<div className={styles.end_form}>*/}
            {/*    <h3 className={'font-16 w500'} style={{marginBottom: 24}}>*/}
            {/*        Для получения полной версии укажите свои данные:*/}
            {/*    </h3>*/}
            {/*    <ServiceForm type={page}></ServiceForm>*/}
            {/*</div>*/}
        </div>
    );
}
