import styles from './index.module.scss';
import LinkedIn from '../logo1.svg';
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Apartment';
import DescriptionIcon from '@mui/icons-material/Description';
import {useTranslation} from "react-i18next";

export default function OpenFeedback({item}) {
    const { t, i18n } = useTranslation();
    return (
        <div className={styles.open_feedback_card}>
            <h2>
                {
                    t("clients_about_us.review.company_review")
                }
            </h2>
            <div style={{width: '100%'}}>
                <div className={styles.open_feedback_card__row}>
                    <div className={styles.open_feedback_card__avatar}>
                        <div className={styles.open_feedback_card__image}>
                            <img alt={"avatar"} src={item?.avatar} />
                        </div>
                        <a href={item?.linkedin} className={styles.open_feedback_card__linkedin}>
                            <img alt={"linked in logo"} src={LinkedIn} className={styles.open_feedback_card__logo} />
                        </a>
                    </div>
                    <div className={styles.open_feedback_card__info}>
                        <div className={styles.open_feedback_card__pseudoinput}>
                            <p className={'font-14 w500'}>{item?.name}</p>
                            <PersonIcon sx={{color: '#F9B932'}} />
                        </div>
                        <div className={styles.open_feedback_card__pseudoinput}>
                            <p className={'font-14 w500'}>{item?.company_name}</p>
                            <BusinessIcon sx={{color: '#F9B932'}} />
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.open_feedback_card__pseudoinput}>
                <p className={'font-14 w400'}>{item?.text}</p>
                <DescriptionIcon sx={{color: '#F9B932'}} />
            </div>
        </div>
    );
}
