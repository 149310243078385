import {ToggleButton, ToggleButtonGroup} from '@mui/material';

export default function SliderTabs({pages, page, setPage}) {
    return (
        <div style={{}}>
            <ToggleButtonGroup
                className={'standard_toggle'}
                value={page}
                exclusive
                onChange={(e) => {
                    setPage(e.target.value);
                }}
                sx={{mt: 3}}
            >
                {Object.keys(pages).map((value) => (
                    <ToggleButton value={value} key={value}>
                        {pages[value].title}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
        </div>
    );
}
