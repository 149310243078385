import './Footer.scss';
import {MasterCard} from './MasterCard'
import {Visa} from './Visa'
import {useTranslation} from "react-i18next";
// import Telegram from './Telegram.svg';
// import Instagram from './Instagram.svg';
// import Whatsapp from './Whatsapp.svg';

export default function Footer() {
    const { t, i18n } = useTranslation();
    const currentYear = new Date().getFullYear();
    return (
        <footer className={'footer'}>
            <div className={'footer__div'}>
                <p className={'copyright_text'}>Copyright © {currentYear} TDS media</p>
                <div className={'offerts_block'}>
                    <a href="/offerts/privacypolicy.pdf" target="_blank" className={'offert_link'}>
                        {
                            t("footer.privacy_policy")
                        }
                    </a>
                    <a href="/offerts/public-offert.pdf" target="_blank" className={'offert_link'}>
                        {
                            t("footer.public_offer")
                        }
                    </a>
                    <div className={'payment'}>
                        <a href="/offerts/payment.pdf" target="_blank" className={'offert_link'}>
                            {
                                t("footer.payment_procedure")
                            }
                        </a>
                        <div className={'payment_icons'}>
                            <Visa />
                            <MasterCard />
                        </div>
                    </div>
                    
                </div>
                {/*<div className={'footer__contacts'}>*/}
                {/*    <a className={'footer__contact'}>*/}
                {/*        <img src={Telegram} />*/}
                {/*    </a>*/}
                {/*    <a className={'footer__contact'}>*/}
                {/*        <img src={Whatsapp} />*/}
                {/*    </a>*/}
                {/*    <a className={'footer__contact'}>*/}
                {/*        <img src={Instagram} />*/}
                {/*    </a>*/}
                {/*</div>*/}
            </div>
        </footer>
    );
}
