import './index.scss';
import React, {useContext, useEffect, useState} from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import EmailIcon from '@mui/icons-material/Email';
import BusinessIcon from '@mui/icons-material/Apartment';
import DescriptionIcon from '@mui/icons-material/Description';
import PersonIcon from '@mui/icons-material/Person';
import {feedback_form_post} from '../../../api/FormsApi';
import LinkedinIco from '../../../static/images/linkedinIco';
import MessageContext from '../../../context/MessageProvider';
import {useTranslation} from "react-i18next";

export default function FeedbackForm({close}) {
    const [name, setName] = useState('');
    const [linkedin, setLinkedin] = useState('');
    const [email, setEmail] = useState('');
    const [company, setCompany] = useState('');
    const [text, setText] = useState('');
    const [disabled, setDisabled] = useState(true);
    const {setMessage} = useContext(MessageContext);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (name != '' && linkedin != '' && email != '' && company != '' && text != '') {
            setDisabled(false);
        } else {
            if (!disabled) {
                setDisabled(true);
            }
        }
    }, [name, linkedin, email, company, text]);

    const handleSubmit = (e) => {
        e.preventDefault();
        async function postData() {
            const response = await feedback_form_post({name, linkedin, company, email, text});
            if (response.status === 200) {
                setMessage('Отзыв успешно отправлен!');
                close();
            }
        }
        postData();

        // handle form submission
    };

    return (
        <Box component="form" onSubmit={handleSubmit} className={`feedback_form`}>
            <h2>
                {
                    t("clients_about_us.review.title_modal")
                }
            </h2>

            <TextField
                id="name"
                label={`${t("clients_about_us.review.full_name_input")}`}
                variant="outlined"
                margin="normal"
                className={'form_field'}
                fullWidth
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                InputProps={{
                    endAdornment: <PersonIcon sx={{color: '#FDBA2F'}} />,
                }}
            />
            <TextField
                id="linkedin"
                label={`${t("clients_about_us.review.link_input")}`}
                variant="outlined"
                margin="normal"
                className={'form_field'}
                fullWidth
                value={linkedin}
                onChange={(e) => setLinkedin(e.target.value)}
                required
                InputProps={{
                    endAdornment: <LinkedinIco />,
                }}
            />
            <TextField
                id="company"
                label={`${t("clients_about_us.review.company_name_input")}`}
                variant="outlined"
                margin="normal"
                className={'form_field'}
                fullWidth
                value={company}
                onChange={(e) => setCompany(e.target.value)}
                required
                InputProps={{
                    endAdornment: <BusinessIcon sx={{color: '#FDBA2F'}} />,
                }}
            />
            <TextField
                id="email"
                label={`${t("clients_about_us.review.email_input")}`}
                variant="outlined"
                margin="normal"
                type="email"
                className={'form_field'}
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                InputProps={{
                    endAdornment: <EmailIcon sx={{color: '#FDBA2F'}} />,
                }}
            />
            <div className="form-container">
                <TextField
                    id="feedback"
                    label={`${t("clients_about_us.review.review_input")}`}
                    variant="outlined"
                    margin="normal"
                    required
                    className={'form_field'}
                    multiline
                    rows={8}
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    InputProps={{
                        classes: {
                            root: 'text-area-input-root',
                            input: 'text-area-input',
                        },
                    }}
                />
                <DescriptionIcon className="text-area-icon" />
            </div>

            <div style={{display: 'flex', justifyContent: 'center'}}>
                <Button
                    variant="filled"
                    color="primary"
                    type="submit"
                    className={'button-filled'}
                    sx={{
                        mt: 3,
                        alignSelf: 'center',
                        display: 'flex',
                        justifySelf: 'center',
                    }}
                    disabled={disabled}
                >
                    {
                        t("clients_about_us.review.title_btn")
                    }
                </Button>
            </div>
        </Box>
    );
}
