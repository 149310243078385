export default function MedalIco() {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.75001 0.400635C9.55001 0.400635 9.35001 0.500635 9.25001 0.600635L7.85001 1.90063L6.25001 1.60063C5.75001 1.50063 5.45001 1.80063 5.35001 2.20063L5.05001 4.00063L3.35001 4.80063C2.95001 5.00063 2.85001 5.40063 3.05001 5.80063L3.85001 7.40063L3.05001 9.00064C2.85001 9.40064 3.05001 9.80064 3.35001 10.0006L5.05001 10.8006L5.35001 12.6006C5.45001 13.0006 5.85001 13.3006 6.25001 13.2006L8.15001 12.9006L9.55001 14.2006C9.85001 14.5006 10.35 14.5006 10.65 14.2006L12.05 12.9006L13.95 13.2006C14.35 13.3006 14.75 13.0006 14.85 12.6006L15.15 10.8006L16.85 10.0006C17.25 9.80064 17.35 9.40064 17.15 9.00064L16.05 7.40063L16.85 5.80063C17.05 5.40063 16.95 5.00063 16.55 4.80063L14.85 4.00063L14.55 2.20063C14.45 1.80063 14.05 1.50063 13.65 1.60063L11.75 1.90063L10.35 0.600635C10.25 0.400635 9.95001 0.400635 9.75001 0.400635ZM4.05001 11.5006L0.950012 17.7006L4.55001 17.2006L6.35001 20.4006L8.95001 15.1006L8.75001 14.9006L7.75001 14.0006L6.35001 14.2006C5.45001 14.3006 4.55001 13.7006 4.35001 12.8006L4.05001 11.5006ZM15.85 11.5006L15.55 12.8006C15.35 13.6006 14.65 14.2006 13.85 14.2006H13.65L12.25 14.0006L11.25 14.9006L11.05 15.1006L13.65 20.4006L15.45 17.3006L19.05 17.8006L15.85 11.5006Z"
                fill="#FDBA2F"
            />
        </svg>
    );
}
