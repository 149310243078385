import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {useLocation, useNavigate} from "react-router";
import {useState} from "react";
import {useTranslation} from "react-i18next";

export  const  SelectLang = ({active}) => {
    const location = useLocation()
    const pathLang = location.pathname.split('/')[1] === "en" ? "en" : "ru"
    const [lang, setLang] = useState(pathLang)
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const handleChange = (event) => {
       const lang = event.target.value
        setLang(lang)
        i18n.changeLanguage(lang)
        navigate(`/${lang === "en" ? lang : ''}`);
        window.location.reload()
    };

    return (
        <Box sx={{ minWidth: 45, minHeight: 36}}>
            <FormControl fullWidth>
                <Select
                    sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                            border: "none !important",
                            color: "#fff !important"
                        },
                        "& .MuiSelect-select, .MuiSvgIcon-root": {
                            color: {
                                xs: "#000 !important",
                                md: `${active ? '#0F2C52' : 'white'} !important`,
                            }
                        },
                    }}
                    value={lang}
                    onChange={handleChange}
                >
                    <MenuItem
                        sx={{
                            '&:hover': {
                                backgroundColor: '#FDBA2F !important',
                                color: "#fff !important"
                            },
                        }}
                        value={"ru"}
                    >
                        RU
                    </MenuItem>
                    <MenuItem
                        sx={{
                            '&:hover': {
                                backgroundColor: '#FDBA2F !important',
                                color: "#fff !important"
                            },
                        }}
                        value={"en"}
                    >
                        EN
                    </MenuItem>
                </Select>
            </FormControl>
        </Box>
    );
}