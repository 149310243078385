import styles from './index.module.scss';
import React, {useContext, useEffect, useState} from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import PersonIcon from '@mui/icons-material/Person';
import WorkIcon from '@mui/icons-material/Work';
import {certificate_post} from '../../../api/FormsApi';
import MessageContext from '../../../context/MessageProvider';
import MedalIcon from '../../../static/images/icons/MedalIco';
import Youtube from '../../../static/images/LOGO.svg';

export default function CertificateForm({close}) {
    const [certNo, setCertNo] = useState('');
    const [email, setEmail] = useState('');
    const [companyRep, setCompanyRep] = useState('');
    const [phone, setPhone] = useState('');
    const [position, setPosition] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [sent, setSent] = useState(false);

    const {setMessage} = useContext(MessageContext);

    useEffect(() => {
        if (certNo != '' && phone != '' && email != '' && companyRep != '' && position != '') {
            setDisabled(false);
        } else {
            if (!disabled) {
                setDisabled(true);
            }
        }
    }, [certNo, phone, email, companyRep, position]);

    const handleSubmit = (e) => {
        e.preventDefault();
        async function postData() {
            const response = await certificate_post({
                number: certNo,
                name: companyRep,
                position,
                email,
                phone,
            });
            if (response.status) {
                if (response.status === 200) {
                    setMessage('Заявка успешно отправлена!');
                    setSent(true);
                }
            } else {
                setMessage(response.response.data.message);
                setSent(false);
            }
        }
        postData();
    };

    return (
        <Box component="form" onSubmit={handleSubmit} className={`${styles.tender_form}`}>
            <img src={Youtube}></img>

            {!sent ? (
                <>
                    <p
                        className={'font-20 w600'}
                        style={{margin: '24px 0 24px 0', textAlign: 'center'}}
                    >
                        Форма активации сертификата
                    </p>
                    <TextField
                        id="certificate_no"
                        label="Номер сертификата"
                        variant="outlined"
                        type={'number'}
                        margin="normal"
                        className={'form_field'}
                        fullWidth
                        required
                        value={certNo}
                        onChange={(e) => setCertNo(e.target.value)}
                        InputProps={{
                            endAdornment: <MedalIcon style={{color: '#FDBA2F'}} />,
                            inputProps: {min: 0, max: 999999},
                        }}
                    />
                    <TextField
                        id="company_represent"
                        label="Имя представителя Компании"
                        variant="outlined"
                        margin="normal"
                        className={'form_field'}
                        fullWidth
                        required
                        value={companyRep}
                        onChange={(e) => setCompanyRep(e.target.value)}
                        InputProps={{
                            endAdornment: <PersonIcon sx={{color: '#FDBA2F'}} />,
                        }}
                    />
                    <TextField
                        id="position"
                        label="Должность"
                        variant="outlined"
                        margin="normal"
                        className={'form_field'}
                        fullWidth
                        required
                        value={position}
                        onChange={(e) => setPosition(e.target.value)}
                        InputProps={{
                            endAdornment: <WorkIcon sx={{color: '#FDBA2F'}} />,
                        }}
                    />
                    <TextField
                        id="email"
                        label="E-mail"
                        variant="outlined"
                        margin="normal"
                        type="email"
                        className={'form_field'}
                        fullWidth
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        InputProps={{
                            endAdornment: <EmailIcon sx={{color: '#FDBA2F'}} />,
                        }}
                    />
                    <TextField
                        id="phone"
                        label="Телефон"
                        variant="outlined"
                        margin="normal"
                        type="tel"
                        className={'form_field'}
                        fullWidth
                        required
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        InputProps={{
                            endAdornment: <PhoneIcon sx={{color: '#FDBA2F'}} />,
                        }}
                    />

                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <Button
                            variant="filled"
                            color="primary"
                            type="submit"
                            className={'button-filled'}
                            disabled={disabled}
                            sx={{
                                mt: 3,
                                alignSelf: 'center',
                                display: 'flex',
                                justifySelf: 'center',
                            }}
                        >
                            Активировать
                        </Button>
                    </div>
                </>
            ) : (
                <>
                    <p
                        className={'font-20 w600'}
                        style={{margin: '24px 0 24px 0', textAlign: 'center'}}
                    >
                        Сертификат активирован
                    </p>
                    <p style={{textAlign: 'left', maxWidth: 340, margin: '0 48px'}}>
                        Спасибо! Ваш сертификат активирован. В течении 2-х рабочих дней, наш
                        менеджер свяжется с вами.
                    </p>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <Button
                            variant="filled"
                            color="primary"
                            onClick={() => {
                                close();
                            }}
                            className={'button-filled'}
                            sx={{
                                mt: 3,
                                alignSelf: 'center',
                                display: 'flex',
                                justifySelf: 'center',
                            }}
                        >
                            Закрыть
                        </Button>
                    </div>
                </>
            )}
        </Box>
    );
}
