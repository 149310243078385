import {Swiper, SwiperSlide} from 'swiper/react';
import {nanoid} from 'nanoid';
import {useMediaQuery} from '@mui/material';
import 'swiper/css';
import PropTypes from 'prop-types';
import Layout from '../Switchers/ServiceMobile/Components/Layout';
import {memo} from 'react';

// Important comment:
// You can think that you can combine two components into the same
// But in this way you will break the right work on resizing
// For some reasons, just props updates do not rerender the component

export function DesktopSwiper({data, props, renderComponent}) {
    return (
        <Swiper {...props}>
            {data?.map((card) => (
                <SwiperSlide key={nanoid()}>{renderComponent(card)}</SwiperSlide>
            ))}
        </Swiper>
    );
}

export function MobileSwiper({data, props, renderComponent}) {
    return (
        <Swiper {...props}>
            {data.map((card) => (
                <SwiperSlide key={nanoid()}>{renderComponent(card)}</SwiperSlide>
            ))}
        </Swiper>
    );
}

export const SwiperComponents = memo(({data, props}) => {
    return (
        <Swiper {...props}>
            {data.map((component) => (
                <SwiperSlide key={nanoid()}>{component}</SwiperSlide>
            ))}
        </Swiper>
    );
});

export function SwiperComponentsMobile(props) {
    return (
        <Swiper {...props.props}>
            {props.data.map((component) => (
                <SwiperSlide key={nanoid()}>
                    <Layout data={component} page={props.page} />
                </SwiperSlide>
            ))}
        </Swiper>
    );
}

export default function SwiperAdaptiveTemplate({
    data,
    mp: mobileProps,
    dp: desktopProps,
    bp: breakpoint,
    rc: renderComponent,
}) {
    const isMobile = useMediaQuery(`(max-width: ${breakpoint}px)`);
    return (
        <>
            {isMobile ? (
                <MobileSwiper props={mobileProps} data={data} renderComponent={renderComponent} />
            ) : (
                <DesktopSwiper props={desktopProps} data={data} renderComponent={renderComponent} />
            )}
        </>
    );
}
SwiperAdaptiveTemplate.propTypes = {
    data: PropTypes.array,
    mp: PropTypes.object.isRequired,
    dp: PropTypes.object.isRequired,
    bp: PropTypes.number,
    rc: PropTypes.func.isRequired,
};
SwiperAdaptiveTemplate.defaultProps = {
    breakpoint: 600,
    bp: 600,
};
