import './CompanyCard.scss';

export default function CompanyCard({card}) {
    return (
        <div className={'company-card'}>
            <div className={'company-card--image'}>
                <img alt={"card"} src={card?.img} />
            </div>

            <div className={'company-card--info'}>
                <h3 className={'font-16 w600'}>{card?.name}</h3>
                <p className={'font-14 w400'}>{card?.text}</p>
            </div>
        </div>
    );
}
