import FullScreenPage from './FullScreenTop/FullScreenPage';
import Content from './Content/Content';
import {useContext, useEffect, useState} from 'react';
import {Header} from './Header/Header';
import FixedButtons from '../../Components/FixedButtons';
import MessageContext from '../../context/MessageProvider';
import {Snackbar} from '@mui/material';
import Slide from '@mui/material/Slide';

export default function Landing() {
    const [active, setActive] = useState(false);

    const {message} = useContext(MessageContext);
    function scrolled() {
        // if (window.innerWidth < 600) {
        //     window.removeEventListener('scroll', scrolled);
        //     return;
        // }
        setActive(true);
        window.removeEventListener('scroll', scrolled);
    }

    useEffect(() => {
        const promise = new Promise((resolve) => {
            window.scrollTo(0, 0);
            resolve('On top');
        });

        promise.then(() => {
            window.addEventListener('scroll', scrolled);
        });
    }, []);

    return (
        <div>
            <Header active={active} />
            <FullScreenPage active={active} />
            <Content active={active} />
            <FixedButtons />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                autoHideDuration={3000}
                TransitionComponent={Slide}
                open={!!message}
                message={message}
            />
        </div>
    );
}
