import React, {useCallback, useState} from 'react';

import 'swiper/css/effect-coverflow';
import './index.scss';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {Swiper, SwiperSlide} from 'swiper/react';
import {nanoid} from 'nanoid';
import SwiperCore, {Navigation} from 'swiper';

SwiperCore.use([Navigation]);

export default function SwiperButtons({setActiveIndex, data}) {
    const [swiperRef, setSwiperRef] = useState();
    const handlePrevious = useCallback(() => {
        swiperRef?.slidePrev();
    }, [swiperRef]);

    const handleNext = useCallback(() => {
        swiperRef?.slideNext();
    }, [swiperRef]);

    const handleSlideChange = useCallback((swiper) => {

        setActiveIndex(data[swiper.activeIndex].name);
    }, []);

    const generalProps = {
        onSwiper: setSwiperRef,
        grabCursor: true,
        loop: false,
        // className: 'swiper-feedback',
    };

    const mobileProps = {
        centeredSlides: true,
        slidesPerView: 1,
        spaceBetween: 32,
    };

    return (
        <div className={'swiper-buttons'}>
            <button onClick={handlePrevious} style={{marginRight: 12}}>
                <ArrowBackIosIcon sx={{height: 24}} />
            </button>
            <Swiper {...generalProps} {...mobileProps} onSlideChange={handleSlideChange} navigation>
                {data.map(({name, label}) => (
                    <SwiperSlide key={nanoid()}>
                        <div className={'button'}>{label}</div>
                    </SwiperSlide>
                ))}
            </Swiper>
            <button onClick={handleNext} style={{marginLeft: 12}}>
                <ArrowForwardIosIcon sx={{height: 24}} />
            </button>
        </div>
    );
}
