import React, {useCallback, useEffect, useState} from 'react';
import {Button} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import './SwitchButtons.scss';
import {useTranslation} from "react-i18next";

export default function SwitchButtons({swiperRef, data, labelFirst}) {
    const [current, setCurrent] = useState(0);
    const { t, i18n } = useTranslation();
    const handlePrevious = useCallback(() => {
        swiperRef?.slidePrev();
        setCurrent(swiperRef?.activeIndex);
    }, [swiperRef]);
    useEffect(() => {
        swiperRef?.slideTo(0);
        setCurrent(0);
    }, [data]);

    const handleNext = useCallback(() => {
        swiperRef?.slideNext();
        setCurrent(swiperRef?.activeIndex);
    }, [swiperRef]);
    return (
        <div className={'paginator'}>
            {current === 0 ? (
                <Button className={'button-filled'} onClick={handleNext}>
                    {labelFirst}
                </Button>
            ) : (
                <>
                    <Button
                        className={'button-filled'}
                        onClick={handlePrevious}
                        sx={{p: '16px 22px!important'}}
                    >
                        <ArrowBackIosIcon sx={{height: 20, width: 20, color: '#000', mr: 2}} />
                        {
                            t("back_btn")
                        }
                    </Button>
                    {data.length === current + 1 ? null : (
                        <Button
                            className={'button-filled'}
                            onClick={handleNext}
                            sx={{p: '16px 22px!important'}}
                        >
                            {
                                t("newt_btn")
                            }
                            <ArrowForwardIosIcon
                                sx={{height: 20, width: 20, color: '#000', ml: 2}}
                            />
                        </Button>
                    )}
                </>
            )}
        </div>
    );
}
