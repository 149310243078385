import styles from './index.module.scss';
import React, {useCallback, useEffect, useState} from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import PersonIcon from '@mui/icons-material/Person';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import LeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import {check_client_login, send_money} from '../../../api/FormsApi';
import {
    Checkbox,
    CircularProgress,
    FormControlLabel,
    FormGroup, Radio, RadioGroup,
    ToggleButton,
    ToggleButtonGroup,
    FormControl,
    FormLabel, styled
} from '@mui/material';
import debounce from 'lodash.debounce';
import axios from 'axios';
import Tenge from "../../../static/icons/Tenge";
import {ReactComponent as YandexDirect1} from '../../../static/images/icons/Yandex_Direct_icon.svg';
import {ReactComponent as YandexDirect2} from '../../../static/images/icons/Yandex_Direct_text.svg';
import {ReactComponent as TikTokIcon} from '../../../static/images/companies/TikTok-vertical.svg';
import TikTokPayMainModal from "../TikTokForm/TikTokPayModal/TikTokPayMainModal";
import Typography from "@mui/material/Typography";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloseIcon from "@mui/icons-material/Close";
import {useTranslation} from "react-i18next";

export default function PayInForm() {
    const [platform, setPlatform] = useState("yandex");
    const [openForm, setOpenForm] = useState(null);
    const [formContent, setFormContent] = useState(null)
    const { t, i18n } = useTranslation();

    return (
        <>
            {/* { */}
            {/*     openForm && */}
            {/*     <ArrowBackIosIcon */}
            {/*     sx={{ */}
            {/*     position: 'absolute', */}
            {/*     left: 24, */}
            {/*     top: 24, */}
            {/*     color: '#000', */}
            {/*     width: 24, */}
            {/*     height: 24, */}
            {/*     zIndex: 10, */}
            {/*     cursor: 'pointer', */}
            {/* }} */}
            {/*     onClick={() => setOpenForm(null)} */}
            {/*     /> */}
            {/* } */}
            {/* { */}
            {/*     openForm ? openForm && platform==="yandex" ? <YandexPay /> : <TikTokPayMainModal /> */}
            {/*         : <ChosePlatform platform ={platform} setPlatform={setPlatform} setOpenForm={setOpenForm}/> */}
            {/* } */}
            < YandexPay />
        </>
    );
}

const ChosePlatform = ({platform, setPlatform, setOpenForm}) => {
    const handleSubmit = (e) => {
        setOpenForm(platform);
    };
    const handleChange = (event, newPlatform) => {
        if (newPlatform !== null && newPlatform !== platform) {
            setPlatform(newPlatform);
        }
    };

    return (
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "24px"}} component="form" onSubmit={handleSubmit} className={`${styles.pay_in_form}`}>
            <h2>Пополнение аккаунта</h2>
            <p>Выберите платформу</p>
            <ToggleButtonGroup
                className={`exclusive_toggle ${styles.platform_toggle}`}
                value={platform}
                exclusive
                onChange={handleChange}
            >
                <ToggleButton value={'yandex'} sx={{lineHeight: '19.5px', width: "50%"}}>
                    <Box sx={{gap: "9px", display: "flex", flexDirection: "row", alignItems: "center"}}>
                        <YandexDirect1/>
                        <YandexDirect2/>
                    </Box>
                </ToggleButton>
                <ToggleButton value={'tiktok'} sx={{lineHeight: '19.5px', width: "50%"}}>
                    <TikTokIcon />
                </ToggleButton>
            </ToggleButtonGroup>

            <Button type="submit" className={'button-filled'}>
                Продолжить
            </Button>
        </Box>
    )
}

const YandexPay = () => {
    const [loading, setLoading] = useState(false);
    const [errorLogin, setErrorLogin] = useState(false);
    const [name, setName] = useState('');
    const [discount, setDiscount] = useState('');
    const [amount, setAmount] = useState('');
    const [amountNDS, setAmountNDS] = useState('');
    const [amountNoNDS, setAmountNoNDS] = useState('');
    const [checked, setChecked] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [page, setPage] = useState('phys');

    const [failed, setFailed] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const { t, i18n } = useTranslation();

    const check_login = async (account_name) => {
        const response = await check_client_login(account_name);
        if (response.status === 200) {
            setDiscount(response.data.discount);
        } else if (response.response?.status === 400) {
            setErrorLogin(true);
        }
    };

    const debounceFn = useCallback(debounce(check_login, 700), []);

    useEffect(() => {
        if (discount) {
            setErrorLogin(false);
        }
        if (name === '') {
            setDisabled(true);
        } else if (errorLogin || discount === '') {
            setDisabled(true);
        } else if (amount === '') {
            setDisabled(true);
        } else if (parseInt(amount) < 5000) {
            setDisabled(true);
        } else if (!checked) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [discount, errorLogin, name, amount, checked]);

    const handleSubmit = (e) => {
        e.preventDefault();
        // setErrorLogin(false);
        pay();
    };

    function pay() {
        setLoading(true);
        axios
            .post('https://team-backend-new.tds.media/external_api/freedom/fill/new/', {
                system: "yd",
                sum: parseInt(amountNDS),
                login: name,
            })
            .then((res) => {
                window.location.replace(`${res.data.url}`);
            })
            .catch((err) => {
                setLoading(false);
                setFailed(true);
                setErrorMessage(err.response.data.message || t("billing_modal.error_message"));
                console.log(err);
            });
    }

    function number_formatter(number) {
        // Convert the number to a string
        const numberString = number.toString();

        // Split the string into integer and decimal parts
        const parts = numberString.split('.');

        // Format the integer part with spaces as thousand separators
        const formattedInteger = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

        // Construct the formatted string with the decimal part if it exists
        const formattedNumber =
            parts.length > 1 ? formattedInteger + '.' + parts[1] : formattedInteger;

        return formattedNumber;
    }
    const handleBack = () => {
        setLoading(false);
        setFailed(false);
    };

    const LoadingContent = () => {
        return (
            <Box className={styles.loader__content__message}>
                <CircularProgress
                    disableShrink
                    thickness="5.5"
                    sx={{
                        color: '#fdba2f ',
                        width: '48px',
                        height: '48px',
                    }}
                />
                <p style={{alignSelf: 'center', color: '#0F2C52'}}>{t("loading")}</p>
            </Box>
        );
    };
    const FailedContent = () => {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignSelf: 'center',
                    justifyContent: 'center',
                    gap: '24px',
                }}
            >
                <Box className={styles.loader__content__message}>
                    <CancelRoundedIcon
                        size={48}
                        sx={{color: '#E20A17', size: 48, width: '48px', height: '48px'}}
                    />
                    <p style={{alignSelf: 'center', fontSize: '16px', fontWeight: '500'}}>{t("billing_modal.error")}</p>
                </Box>
                <p style={{fontSize: '14px', fontWeight: '500', padding: '0 58.5px'}}>
                    {errorMessage}
                </p>
            </Box>
        );
    };
    const Offerts = () => {
        return (
            <p>
                {t("billing_modal.physical.checkbox1")}  <a href="/offerts/public-offert.pdf" target="_blank">{t("billing_modal.physical.public_offer")}</a> {t("billing_modal.physical.checkbox2")} <a href="/offerts/privacypolicy.pdf" target="_blank">{t("billing_modal.physical.privacy_policy")}</a>.
            </p>
        );
    }
    return (
        <Box component="form" onSubmit={handleSubmit} className={`${styles.pay_in_form}`}>
            <>
                <h2>
                    {
                        t("billing_modal.title_modal")
                    }
                </h2>
            </>

            <Typography className={'font-14 w400'} style={{margin: '16px 0 16px 0', textAlign: 'center'}}>
                {
                    t("billing_modal.description_modal")
                }
            </Typography>

            <ToggleButtonGroup
                className={`standard_toggle ${styles.pay_in_form__toggle}`}
                value={page}
                exclusive
                onChange={(e) => {
                    setPage(e.target.value);
                }}
            >
                <ToggleButton value={'phys'} sx={{lineHeight: '19.5px'}}>
                    {
                        t("billing_modal.btn1")
                    }
                </ToggleButton>
                <ToggleButton value={'your'} sx={{lineHeight: '19.5px'}}>
                    {
                        t("billing_modal.btn2")
                    }
                </ToggleButton>
            </ToggleButtonGroup>
            {page === 'phys' ? (
                loading || failed ? (
                    <>
                        <Box className={styles.loader}>
                            {failed ? <FailedContent /> : <LoadingContent />}
                        </Box>
                        {failed && (
                            <Button
                                onClick={handleBack}
                                sx={{color: '#0F2C52', fontWeight: '500', mt: '24px'}}
                            >
                                <LeftIcon />
                                {
                                    t("back_btn")
                                }
                            </Button>
                        )}
                    </>
                ) : (
                    <>
                        <TextField
                            id="name"
                            label={`${t("billing_modal.physical.input1")}`}
                            variant="outlined"
                            margin="normal"
                            className={'form_field'}
                            error={errorLogin}
                            helperText={errorLogin ? t("billing_modal.physical.input_error1") : ''}
                            fullWidth
                            required
                            value={name}
                            onChange={(e) => {
                                setDiscount('');
                                setErrorLogin(false);
                                setName(e.target.value);
                                debounceFn(e.target.value);
                            }}
                            onBlur={(e) => {
                                check_login(e.target.value);
                            }}
                            InputProps={{
                                endAdornment: (
                                    <PersonIcon sx={{color: '#FDBA2F', width: 20, height: 20}} />
                                ),
                            }}
                        />
                        <TextField
                            id="amount"
                            label={`${t("billing_modal.physical.input2")}`}
                            variant="outlined"
                            margin="normal"
                            type="number"
                            className={'form_field'}
                            fullWidth
                            required
                            value={amount}
                            onChange={(e) => {
                                setAmount(e.target.value);
                                if (e.target.value === '') {
                                    setAmountNoNDS('');
                                    setAmountNDS('');
                                }
                                setAmountNoNDS(
                                    (parseFloat(e.target.value) * (1 + discount / 100)).toFixed(2)
                                );
                                setAmountNDS((e.target.value * 1.12).toFixed(2));
                            }}
                            InputProps={{
                                endAdornment: <Tenge />,
                            }}
                        />
                        <TextField
                            id="amountNDS"
                            label={`${t("billing_modal.physical.input3")}`}
                            variant="outlined"
                            margin="normal"
                            className={'form_field'}
                            helperText={discount ? `${t("billing_modal.physical.discount")} ${discount} %` : ''}
                            fullWidth
                            value={amount !== '' ? number_formatter(amountNDS) : ''}
                            sx={{
                                pointerEvents: 'none',
                            }}
                            InputProps={{
                                endAdornment: <Tenge />,
                            }}
                        />
                        <TextField
                            id="amountNoNDS"
                            label={`${t("billing_modal.physical.input4")}`}
                            variant="outlined"
                            margin="normal"
                            className={'form_field'}
                            fullWidth
                            sx={{pointerEvents: 'none'}}
                            value={amount !== '' ? number_formatter(amountNoNDS) : ''}
                            InputProps={{
                                endAdornment: <Tenge />,
                            }}
                        />
                        <FormGroup sx={{mt: "12px"}}>
                            <FormControlLabel
                                className={'default_checkbox'}
                                control={
                                    <Checkbox
                                        sx={{
                                            color: '#000',
                                            '& .MuiSvgIcon-root': {fontSize: 24},
                                            '&.Mui-checked': {
                                                color: '#000',
                                            },
                                        }}
                                        checked={checked}
                                        onChange={(e) => setChecked(e.target.checked)}
                                    />
                                }
                                label={<Offerts />}
                            />
                        </FormGroup>

                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <Button
                                disabled={disabled}
                                variant="filled"
                                color="primary"
                                type="submit"
                                className={'button-filled'}
                                sx={{
                                    mt: 3,
                                    alignSelf: 'center',
                                    display: 'flex',
                                    justifySelf: 'center',
                                    pointerEvents: checked ? 'initial' : 'none',
                                }}
                            >
                                {t("billing_modal.physical.payment_btn")}
                            </Button>
                        </div>
                    </>
                )
            ) : (
                <Box>
                    <p className={'font-14 w500'} style={{textAlign: 'left'}}>
                        {t("billing_modal.legal.text1")}{' '}
                        <span style={{fontWeight: 'bold'}}>
                            {' '}
                            {t("billing_modal.legal.text2")}{' '}
                        </span>{' '}
                        {t("billing_modal.legal.text3")}
                    </p>
                    <Box className={styles.pay_in_form__contact_info}>
                        <Box>
                            <p className={styles.pay_in_form__contact_info__text}>Email</p>
                            <a href="mailto:billing@tds.media?subject = Support&body = Message" className={styles.pay_in_form__contact_info__text_bold}>
                                billing@tds.media
                            </a>
                        </Box>
                        <Box>
                            <p className={styles.pay_in_form__contact_info__text}>{t("billing_modal.legal.phone")}</p>
                            <a href="tel:+77001222224" className={styles.pay_in_form__contact_info__text_bold}>
                                +7 727 222 22 24
                            </a>
                        </Box>
                    </Box>
                </Box>
            )}
        </Box>
    )
}