import Singapore from '../../../../static/images/cities/singapore.jpeg';
import Almaty from '../../../../static/images/cities/almaty.jpeg';
import Tashkent from '../../../../static/images/cities/tashkent.png';
import './Contacts.scss';
import {useTranslation} from "react-i18next";

export default function Contacts() {
    const { t, i18n } = useTranslation();
    return (
        <div className={'contacts'}>
            <div className={'contact main-container'}>
                <img alt={'Almaty image'} src={Almaty} className={'contact--image'} />
                <div className={'contact--info'}>
                    <h2 className={'font-20 w600'}>Almaty Kazakhstan</h2>
                    <p className={'font-16 w400'}>
                        {t("contact.almaty_address.text1")} <br />
                        {t("contact.almaty_address.text2")}
                    </p>
                    <p className={'font-16 w600'}>
                        <a
                            className={'font-16 w600'}
                            style={{textDecoration: 'none', color: '#0F2C52'}}
                            href="tel:+77273390300"
                        >
                            +7 727 339 0300
                        </a>{' '}
                        /{' '}
                        <a
                            className={'font-16 w600'}
                            style={{textDecoration: 'none', color: '#0F2C52'}}
                            href="tel:+77001222224"
                        >
                            +7 700 122 2224
                        </a><br />
                        <a
                            className={'font-16 w600'}
                            style={{textDecoration: 'none', color: '#0F2C52'}}
                            href="mailto:tds@tds.media"
                        >
                            tds@tds.media
                        </a>
                    </p>
                </div>
            </div>

            <div className={'contact main-container'}>
                <img alt={'Singapore image'} src={Singapore} className={'contact--image'} />
                <div className={'contact--info'}>
                    <h2 className={'font-20 w600'}>Singapore</h2>
                    <p className={'font-16 w400'}>
                        {t("contact.singapore_address.text1")} <br />
                        {t("contact.singapore_address.text2")}
                    </p>
                    <a
                        className={'font-16 w600'}
                        style={{textDecoration: 'none', color: '#0F2C52'}}
                        href="tel:+6583387295"
                    >
                        +65 8338 7295
                    </a><br />
                    <a
                        className={'font-16 w600'}
                        style={{textDecoration: 'none', color: '#0F2C52'}}
                        href="mailto:tds@tds.media"
                    >
                        tds@tds.media
                    </a>
                </div>
            </div>

            <div className={'contact main-container'}>
                <img alt={'Toshkent image'} src={Tashkent} className={'contact--image'} />
                <div className={'contact--info'}>
                    <h2 className={'font-20 w600'}>Toshkent Uzbekistan</h2>
                    <p className={'font-16 w400'}>
                        {t("contact.toshkent_address.text1")} <br />
                        {t("contact.toshkent_address.text2")}
                    </p>
                    <a
                        className={'font-16 w600'}
                        style={{textDecoration: 'none', color: '#0F2C52'}}
                        href="tel:+998908130858"
                    >
                        +998 90 813 08 58
                    </a><br />
                    <a
                        className={'font-16 w600'}
                        style={{textDecoration: 'none', color: '#0F2C52'}}
                        href="mailto:tds@tds.media"
                    >
                        tds@tds.media
                    </a>
                </div>
            </div>
        </div>
    );
}
