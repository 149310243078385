import Form from '../../static/images/Send.svg';
import Tel from '../../static/images/Phone.svg';
import OrderForm from '../Forms/OrderForm';
import CustomModal from '../Modal';
import {useState} from 'react';
import styles from './index.module.scss';
import {useTranslation} from "react-i18next";

export default function FixedButtons() {
    const [open, setOpen] = useState(false);
    const { t, i18n } = useTranslation();
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    return (
        <div>
            <div className={styles.buttons}>
                <div className={'tooltip'}>
                    <img src={Form} alt={'order button'} onClick={handleOpen} />
                    <span className="tooltip-text tooltip-text-left">
                        {
                            t("fixed_btn.send_request")
                        }
                    </span>
                </div>

                <div className={'tooltip'}>
                    <a href="tel:+77001222224">
                        <img src={Tel} alt={'phone button'} className={'tooltip'} />
                    </a>
                    <span className="tooltip-text tooltip-text-left">
                        {
                            t("fixed_btn.call")
                        }
                    </span>
                </div>
            </div>
            <CustomModal open={open} setOpen={setOpen}>
                <OrderForm close={handleClose} />
            </CustomModal>
        </div>
    );
}
