import React, {useMemo, useState} from 'react';
import 'swiper/css/effect-fade';
import './SwiperService.scss';
import CustomPaginator from '../CustomPaginator';
import {SwiperComponents} from '../SwipersTemplate';
import SwitchButtons from '../SwitchButtons/SwitchButtons';

export default function SwiperService({slides, isFirstTab, labelFirst}) {
    const [swiperRef, setSwiperRef] = useState();
    const sliderProps = useMemo(
        () => ({
            onSwiper: setSwiperRef,
            // effect: 'fade',
            grabCursor: false,
            slidesPerView: 1,
            className: 'mySwiper',
            allowTouchMove: false,
            // modules: [EffectFade],
        }),
        []
    );
    return (
        <div className={'swiper-service'}>
            <SwiperComponents data={slides} props={sliderProps} />
            {labelFirst !== 'refill' ? (
                isFirstTab ? (
                    <CustomPaginator swiperRef={swiperRef} data={slides}/>
                ) : (
                    <SwitchButtons swiperRef={swiperRef} data={slides} labelFirst={labelFirst}/>
                )
            ) : null}
        </div>
    );
}
