import styles from './index.module.scss';
import React, {useContext, useEffect, useState} from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import PersonIcon from '@mui/icons-material/Person';
import {order_form_post} from '../../../api/FormsApi';
import {Checkbox, FormControlLabel, FormGroup} from '@mui/material';
import BusinessIcon from '@mui/icons-material/Apartment';
import MessageContext from '../../../context/MessageProvider';
import {useTranslation} from "react-i18next";

export default function OrderForm({close}) {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [company, setCompany] = useState('');
    const [phone, setPhone] = useState('');
    const [checked, setChecked] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const {setMessage} = useContext(MessageContext);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (name != '' && phone != '' && email != '' && company != '' && checked != false) {
            setDisabled(false);
        } else {
            if (!disabled) {
                setDisabled(true);
            }
        }
    }, [name, phone, email, company, checked]);

    const handleSubmit = (e) => {
        e.preventDefault();
        async function postData() {
            const response = await order_form_post({name, phone, email, company});
            if (response.status === 200) {
                setMessage('Заявка успешно отправлена!');
                close();
            }
        }
        postData();

        // handle form submission
    };

    return (
        <Box component="form" onSubmit={handleSubmit} className={`${styles.order_form}`}>
            <h2>
                {
                    t("application_modal.title")
                }
            </h2>
            <p className={'font-14 w400'} style={{margin: '43px 0 40px 0', textAlign: 'left'}}>
                {
                    t("application_modal.sub_title")
                }
            </p>
            <TextField
                id="name"
                label={`${t("application_modal.input1")}`}
                variant="outlined"
                margin="normal"
                className={'form_field'}
                fullWidth
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
                InputProps={{
                    endAdornment: <PersonIcon sx={{color: '#FDBA2F', height: 20, width: 20}} />,
                }}
            />
            <TextField
                id="email"
                label="E-mail"
                variant="outlined"
                margin="normal"
                type="email"
                className={'form_field'}
                fullWidth
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                InputProps={{
                    endAdornment: <EmailIcon sx={{color: '#FDBA2F', height: 20, width: 20}} />,
                }}
            />
            <TextField
                id="company"
                label={`${t("application_modal.input2")}`}
                variant="outlined"
                margin="normal"
                className={'form_field'}
                fullWidth
                required
                value={company}
                onChange={(e) => setCompany(e.target.value)}
                InputProps={{
                    endAdornment: <BusinessIcon sx={{color: '#FDBA2F'}} />,
                }}
            />
            <TextField
                id="phone"
                label={`${t("application_modal.input3")}`}
                variant="outlined"
                margin="normal"
                type="tel"
                className={'form_field'}
                fullWidth
                required
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                InputProps={{
                    endAdornment: <PhoneIcon sx={{color: '#FDBA2F', height: 20, width: 20}} />,
                }}
            />
            <FormGroup sx={{mt: 3}}>
                <FormControlLabel
                    className={'default_checkbox'}
                    control={
                        <Checkbox
                            sx={{
                                color: '#000',
                                '& .MuiSvgIcon-root': {fontSize: 24},
                                '&.Mui-checked': {
                                    color: '#000',
                                },
                            }}
                            checked={checked}
                            onChange={(e) => setChecked(e.target.checked)}
                        />
                    }
                    label={`${t("application_modal.checkbox")}`}
                />
            </FormGroup>

            <div style={{display: 'flex', justifyContent: 'center'}}>
                <Button
                    variant="filled"
                    color="primary"
                    type="submit"
                    className={'button-filled'}
                    disabled={disabled}
                    sx={{
                        mt: 3,
                        alignSelf: 'center',
                        display: 'flex',
                        justifySelf: 'center',
                        pointerEvents: checked ? 'initial' : 'none',
                    }}
                >
                    {
                        t("application_modal.send_request_btn")
                    }
                </Button>
            </div>
        </Box>
    );
}

function MoneyIcon() {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.0181 1.6499C7.92293 1.6499 6.01366 2.34189 4.88436 2.90754C4.78257 2.95851 4.68717 3.00846 4.5985 3.05677C4.42306 3.15239 4.2741 3.24165 4.15503 3.3199L5.43997 5.21147L6.04476 5.45224C8.4089 6.64508 11.5792 6.64508 13.9434 5.45224L14.6301 5.09595L15.845 3.3199C15.667 3.20121 15.4208 3.0572 15.1178 2.90409C15.0993 2.89475 15.0807 2.88539 15.0618 2.87599C13.9374 2.3165 12.0745 1.6499 10.0181 1.6499ZM7.0296 3.79129C6.56718 3.70597 6.11281 3.5883 5.68392 3.45329C6.74219 2.98337 8.3193 2.4849 10.0181 2.4849C11.1952 2.4849 12.3081 2.72423 13.2286 3.02728C12.1498 3.17956 10.9987 3.43661 9.90209 3.75385C9.03924 4.00346 8.03064 3.97599 7.0296 3.79129Z"
                fill="#FDBA2F"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14.4333 6.14014L14.3195 6.1975C11.7188 7.5097 8.26932 7.5097 5.66863 6.1975L5.5605 6.14298C1.65381 10.4299 -2.44464 18.4688 10.0181 18.3483C22.4723 18.228 18.3097 10.2819 14.4333 6.14014ZM10.7145 9.16468H9.28556V9.83268C8.82122 9.83159 8.37474 9.99963 8.04062 10.3012C7.70657 10.6027 7.51123 11.014 7.49599 11.4479C7.48075 11.8818 7.64683 12.3042 7.95899 12.6255C8.2712 12.9469 8.70499 13.1419 9.16841 13.1693L9.28556 13.1727H10.7145L10.7788 13.178C10.8612 13.192 10.9357 13.2325 10.9893 13.2926C11.043 13.3526 11.0724 13.4284 11.0724 13.5067C11.0724 13.5849 11.043 13.6607 10.9893 13.7208C10.9357 13.7808 10.8612 13.8214 10.7788 13.8353L10.7145 13.8407H7.85662V15.1767H9.28556V15.8447H10.7145V15.1767C11.1788 15.1778 11.6253 15.0097 11.9594 14.7082C12.2935 14.4067 12.4888 13.9954 12.5041 13.5615C12.5193 13.1276 12.3532 12.7052 12.0411 12.3839C11.7289 12.0625 11.2951 11.8674 10.8316 11.84L10.7145 11.8367H9.28556L9.22127 11.8313C9.13889 11.8174 9.06437 11.7768 9.01072 11.7168C8.95707 11.6567 8.92764 11.5809 8.92764 11.5027C8.92764 11.4244 8.95707 11.3486 9.01072 11.2886C9.06437 11.2285 9.13889 11.188 9.22127 11.174L9.28556 11.1687H12.1434V9.83268H10.7145V9.16468Z"
                fill="#FDBA2F"
            />
        </svg>
    );
}
