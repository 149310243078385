import React from "react";

export default function Tenge() {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17.2322 6.14286H2.76791C2.50273
                 6.14286 2.28577 6.35982 2.28577
                 6.625V8.875C2.28577 9.14018 2.50273
                 9.35714 2.76791 9.35714H8.39291V18.5179C8.39291
                 18.783 8.60987 19 8.87505 19H11.1251C11.3902
                 19 11.6072 18.783 11.6072 18.5179V9.35714H17.2322C17.4974
                 9.35714 17.7143 9.14018 17.7143 8.875V6.625C17.7143 6.35982
                 17.4974 6.14286 17.2322 6.14286ZM17.2322 1H2.76791C2.50273
                 1 2.28577 1.21696 2.28577 1.48214V3.73214C2.28577 3.99732 2.50273
                 4.21429 2.76791 4.21429H17.2322C17.4974 4.21429 17.7143 3.99732
                 17.7143 3.73214V1.48214C17.7143 1.21696 17.4974 1 17.2322 1Z"
                fill="#FDBA2F"
            />
        </svg>
    );
}