import React, {useCallback, useState} from 'react';
import {useMediaQuery} from '@mui/material';

import 'swiper/css/effect-coverflow';
import './index.scss';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {Swiper, SwiperSlide} from 'swiper/react';
import {nanoid} from 'nanoid';
import FeedbackCard from '../../Cards/FeedbackCard';
import Button from '@mui/material/Button';
import CustomModal from '../../Modal';
import FeedbackForm from '../../Forms/FeedbackForm';
import OpenFeedback from '../../Cards/FeedbackCard/OpenFeedback';
import {useTranslation} from "react-i18next";


export default function SwiperFeedback({data}) {
    const isMobile = useMediaQuery(`(max-width: 700px)`);
    const isMiddle = useMediaQuery(`(max-width: 1040px)`);
    const { t, i18n } = useTranslation();
    const [swiperRef, setSwiperRef] = useState();
    const handlePrevious = useCallback(() => {
        swiperRef?.slidePrev();
    }, [swiperRef]);

    const handleNext = useCallback(() => {
        swiperRef?.slideNext();
    }, [swiperRef]);

    const generalProps = {
        onSwiper: setSwiperRef,
        grabCursor: true,
        loop: true,
        // className: 'swiper-feedback',
    };

    const mobileProps = {
        centeredSlides: true,
        slidesPerView: 1,
        spaceBetween: 32,
    };
    const desktopProps = {
        slidesPerView: 3,
        spaceBetween: 32,
    };

    const middleProps = {
        slidesPerView: 2,
        spaceBetween: 32,
    };

    const [open, setOpen] = useState(false);
    const [activeItem, setActiveItem] = useState(null);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [openCard, setOpenCard] = useState(false);
    const handleOpenCard = (item) => {
        setActiveItem(item);
        setOpenCard(true);
    };
    const handleCloseCard = () => setOpenCard(false);

    return (
        <>
            {!isMobile ? (
                <>
                    <h2 className={'font-32 w700'}>
                        {
                            t('clients_about_us.title_header')
                        }
                    </h2>
                    <div className={'swiper-feedback'}>
                        <button onClick={handlePrevious}>
                            <ArrowBackIosIcon sx={{height: 24}} />
                        </button>
                        <Swiper {...generalProps} {...(isMiddle ? middleProps : desktopProps)}>
                            {data.map((card) => (
                                <SwiperSlide key={nanoid()}>
                                    <FeedbackCard
                                        item={card}
                                        onClick={() => {
                                            handleOpenCard(card);
                                        }}
                                    />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        <button onClick={handleNext}>
                            <ArrowForwardIosIcon sx={{height: 24}} />
                        </button>
                    </div>
                </>
            ) : (
                <>
                    <div
                        className={'swiper-feedback'}
                        style={{justifyContent: 'space-between', padding: '0 16px', margin: 0}}
                    >
                        <h2 className={'font-20 w600'}>
                            {
                                t('clients_about_us.title_header')
                            }
                        </h2>
                        <button onClick={handlePrevious}>
                            <ArrowBackIosIcon sx={{height: 20}} />
                        </button>
                        <button onClick={handleNext}>
                            <ArrowForwardIosIcon sx={{height: 20}} />
                        </button>
                    </div>
                    <div className={'swiper-feedback'}>
                        <Swiper {...generalProps} {...mobileProps}>
                            {data.map((card) => (
                                <SwiperSlide key={nanoid()}>
                                    <FeedbackCard
                                        item={card}
                                        onClick={() => {
                                            handleOpenCard(card);
                                        }}
                                    />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </>
            )}
            <Button className={'button-filled'} onClick={handleOpen} sx={{mt: 3}}>
                {
                    t('clients_about_us.review.title_btn')
                }
            </Button>
            <CustomModal open={openCard} setOpen={setOpenCard} handleClose={handleCloseCard}>
                <OpenFeedback item={activeItem} />
            </CustomModal>
            <CustomModal open={open} setOpen={setOpen}>
                <FeedbackForm close={handleClose} />
            </CustomModal>
        </>
    );
}
