import styles from './index.module.scss';
import LinkedIn from './logo1.svg';
import {useTranslation} from "react-i18next";

export default function FeedbackCard({item, onClick}) {
    const {avatar, company_name, name, linkedin, text} = item
    return (
        <div className={styles.feedback_card} onClick={onClick}>
            <div className={styles.feedback_card__image}>
                <img alt={"avatar"} src={avatar ? avatar : ""} />
            </div>
            <div className={styles.feedback_card__info}>
                <p className={`font-16 w600 ${styles.feedback_card__company}`}>
                    {
                        company_name ? company_name : ""
                    }
                </p>
                <div className={styles.feedback_card__line}>
                    <p className={'font-12 w600'}>
                        {
                            name ? name : ""
                        }
                    </p>
                    <a href={linkedin ? linkedin : ""}>
                        <img alt={"linked in logo"} src={LinkedIn} className={styles.feedback_card__logo} />
                    </a>
                </div>
                <p className={`font-12 w300 ${styles.feedback_card__feedback}`}>
                    {
                        text ? text.slice(0, 100) : ""
                    }...
                </p>
            </div>
        </div>
    );
}
