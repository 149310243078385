import Landing from './Pages/Landing/Landing';
import {MessageProvider} from './context/MessageProvider';
import { Route, Routes } from "react-router-dom";
import TikTokPage from "./Pages/TikTokPage/TikTokPage";

function App() {
    return (
        <div className="App">
            <MessageProvider>
                <Routes>
                    <Route path="/" element={<Landing />} />
                    <Route path="/en" element={<Landing />} />
                    {/* <Route path="/tiktok" element={<TikTokPage />} /> */}
                </Routes>
            </MessageProvider>
        </div>
    );
}

export default App;
