import axios from 'axios';

const BASE_URL = 'https://backend.tds.media/';
export function service_form_post({type, name, company, email, phone}) {
    const api = 'feedback/new_ticket/';
    return axios
        .post(
            `${BASE_URL}${api}`,
            {type, name, company, email, phone},
            {
                headers: {
                    'content-type': 'application/json',
                },
            }
        )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.error(error);
        });
}

export function check_client_login(login) {
    const api = 'https://team-backend-new.tds.media/external_api/yd/fill/check/';
    return axios
        .post(
            `${api}`,
            {login: login},
            {
                headers: {
                    'content-type': 'application/json',
                },
            }
        )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.error(error);
            return error;
        });
}

export function check_client_login_tiktok(login) {
    const api = 'https://team-backend-new.tds.media/fills/tiktok/account/check/';
    return axios
        .post(
            `${api}`,
            {account: login},
            {
                headers: {
                    'content-type': 'application/json',
                },
            }
        )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.error(error);
            return error;
        });
}

export function send_money(login, sum) {
    const api = 'https://team-backend-new.tds.media/external_api/yd/fill/new/';
    return axios
        .post(
            `${api}`,
            {login: login, sum_vat: sum},
            {
                headers: {
                    'content-type': 'application/json',
                },
            }
        )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.error(error);
            return error;
        });
}

// export function payment({amount, company, email, phone}) {
//     const api = 'https://cards.pitech.kz/gw/payments/cards/charge';
//     const currency = 'KZT';
//     const payload = {
//         name: 'TDS Media',
//         firstName: 'TDS',
//         lastName: 'Media',
//         birthDate: '1988-02-03',
//         phone: '77785568094',
//     };
//     const successReturnUrl = 'https://example.com/success';
//     const errorReturnUrl = '';
//     return axios
//         .post(
//             `${api}`,
//             {type, name, company, email, phone},
//             {
//                 headers: {
//                     'content-type': 'application/json',
//                 },
//             }
//         )
//         .then((response) => {
//             console.log(response);
//             return response;
//         })
//         .catch((error) => {
//             console.error(error);
//         });
// }

export function tender_form_post({name, company, email, phone}) {
    const api = 'feedback/tender_create/';
    return axios
        .post(
            `${BASE_URL}${api}`,
            {name, company, email, phone},
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.error(error);
        });
}

export function certificate_post({number, name, position, email, phone}) {
    const api = 'certificates/activate_certificate/';
    console.log({number, name, position, email, phone});
    return axios
        .post(
            `${BASE_URL}${api}`,
            {number, name, position, email, phone},
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.error(error);
            return error;
        });
}

export function order_form_post({name, phone, email, company}) {
    const api = 'feedback/main_review/';
    return axios
        .post(
            `${BASE_URL}${api}`,
            {name, phone, email, company},
            {
                headers: {
                    'content-type': 'application/json',
                },
            }
        )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.error(error);
        });
}

export function feedback_form_post({name, linkedin, company, email, text}) {
    const api = 'feedback/new_review/';
    return axios
        .post(
            `${BASE_URL}${api}`,
            {name, linkedin, company, email, text},
            {
                headers: {
                    'content-type': 'application/json',
                },
            }
        )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.error(error);
        });
}

export function get_team() {
    const api = 'team/get_team/';
    return axios
        .get(BASE_URL + api)
        .then((response) => {
            const data = response.data;
            const updatedData = data.map((member) => {
                return {
                    ...member,
                    avatar: BASE_URL + member.avatar,
                };
            });
            return updatedData;
        })
        .catch((error) => {
            console.error(error);
        });
}
export function get_feedback() {
    const api = 'feedback/review_show/';
    console.log()
    return axios
        .get(BASE_URL + api, {headers:{
                "Accept-Language": localStorage.getItem("i18nextLng") || "ru"
            }})
        .then((response) => {
            const data = response.data;
            const updatedData = data.map((member) => {
                return {
                    ...member,
                    avatar: BASE_URL + member.avatar,
                };
            });
            return updatedData;
        })
        .catch((error) => {
            console.error(error);
        });
}
export function get_riches() {
    const api = 'creative/get_richs/';
    return axios
        .get(BASE_URL + api)
        .then((response) => {
            console.log(response);
            const data = response.data;
            const updatedData = data.map((member) => {
                return {
                    ...member,
                    banner:
                        BASE_URL +
                        'richmedia/' +
                        member.title.charAt(0).toUpperCase() +
                        member.title.slice(1) +
                        '/index.html',
                    image:
                        BASE_URL +
                        'richmedia/' +
                        member.title.charAt(0).toUpperCase() +
                        member.title.slice(1) +
                        '/main.png',
                };
            });
            return updatedData;
        })
        .catch((error) => {
            console.error(error);
        });
}

// export function feedback_form_post() {
//     const api = '/feedback/new_review/';
// }

export function create_tiktok_form_post(type, formData, amount, file) {
    const api = "https://team-backend-new.tds.media/fills/tiktok/create/";
    const data = new FormData();
    data.append('organization_type', type === "phys" ? "Физ. лицо" : "Юр. лицо");
    if (type === "phys") {
        data.append('name', formData.name);
        data.append('first_pay_sum', amount);
        data.append('industry', formData.sub_industry);
        data.append('contact_name', formData.nameContactPerson);
        data.append('placement', formData.site);
        data.append('email', formData.email);
        data.append('phone_number', formData.phone);
    } else {
        data.append('name', formData.name);
        data.append('company_type', formData.company_type);
        data.append('first_pay_sum', amount);
        data.append('industry', formData.sub_industry);
        data.append('contact_name', formData.nameContactPerson);
        data.append('placement', formData.site);
        data.append('email', formData.email);
        data.append('bin_number', formData.bin);
        data.append('phone_number', formData.phone);
        data.append('reference', file)
    }

    return axios
        .post(
            `${api}`,
            data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        )
        .then((response) => {
            console.log("Create tiktok answer:",response)
            return response;
        })
        .catch((error) => {
            console.error(error);
        });
}

export function get_tiktok_categories() {
    const api = 'https://team-backend-new.tds.media/fills/tiktok/industry/list/';
    return axios
        .get(api)
        .then((response) => {
            const data = response.data;
            return data;
        })
        .catch((error) => {
            console.error(error);
        });
}

export function email_verify(email) {
    const api = 'https://team-backend-new.tds.media/fills/verify/send/';
    return axios
        .post(
            api,
            {email},
            {
                headers: {
                    'content-type': 'application/json',
                },
            }
        )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.error(error);
            return error;
        });
}

export function code_verify(email, code) {
    const api = 'https://team-backend-new.tds.media/fills/verify/email/';
    return axios
        .post(
            api,
            {email, code},
            {
                headers: {
                    'content-type': 'application/json',
                },
            }
        )
        .then((response) => {
            console.log(response)
            return response;
        })
        .catch((error) => {
            console.error(error);
            return error;
        });
}
export function pay_link(name, sum, id) {
    const api = 'https://team-backend-new.tds.media/external_api/freedom/fill/new/';
    return axios
        .post(
            api,
            {
                login: `TDS Media | ${name}`,
                sum: sum,
                system: "tiktok",
                application_id: id
            },
            {
                headers: {
                    'content-type': 'application/json',
                },
            }
        )
        .then((response) => {
            console.log(response)
            return response;
        })
        .catch((error) => {
            console.error(error);
            return error;
        });
}


