import {createContext, useEffect, useState} from 'react';

const MessageContext = createContext({});

export const MessageProvider = ({children}) => {
    const [message, setMessage] = useState('');
    useEffect(() => {
        setTimeout(() => {
            setMessage(null);
        }, 3000);
    }, [message]);
    return (
        <MessageContext.Provider value={{message, setMessage}}>{children}</MessageContext.Provider>
    );
};
export default MessageContext;
