import React, {useEffect, useMemo, useState} from 'react';

import './Slider.scss';

import SwiperService from '../../Swiper/SwiperService/SwiperService';
import SliderTabs from './SliderTabs';
import {useTranslation} from "react-i18next";

export default function Slider() {
    const [page, setPage] = useState('tradeView');
    const [loaded, setLoaded] = useState(false);
    const [slides, setSlides] = useState([]);
    const [labelFirstButton, setlLabelFirstButton] = useState('');
    const { t, i18n } = useTranslation();

    const pages = {
        tradeView: {
            title: t('services_block.name_services_btn.btn1'),
            blocks: [
                import('./marketView/slide1/Slide1'),
                import('./marketView/slide2/Slide2'),
                import('./marketView/slide3/Slide3'),
                import('./marketView/slide4/Slide4'),
                import('./marketView/slide5/Slide5'),
                import('./marketView/slide6/Slide6'),
                // import('./marketView/slide7/Slide7'),
            ],
        },
        brandformance: {
            title: t('services_block.name_services_btn.btn2'),
            blocks: [
                import('./Brandformance/slide1/Slide1'),
                import('./Brandformance/slide2/Slide2'),
                import('./Brandformance/slide3/Slide3'),
                // import('./Brandformance/slide4/Slide4'),
            ],
        },
        performance: {
            title: t('services_block.name_services_btn.btn3'),
            blocks: [
                import('./Performance/slide1/Slide1'),
                import('./Performance/slide2/Slide2'),
                import('./Performance/slide3/Slide3'),
                import('./Performance/slide4/Slide4'),
                import('./Performance/slide5/Slide5'),
                // import('./Performance/slide6/Slide6'),
            ],
        },
        research: {
            title: t('services_block.name_services_btn.btn4'),
            blocks: [
                import('./Research/slide1/Slide1'),
                // import('./Research/slide2/Slide2'),
                import('./Research/slide3/Slide3'),
                // import('./Research/slide4/Slide4'),
            ],
        },
        creative: {
            title: t('services_block.name_services_btn.btn5'),
            blocks: [
                import('./Creatives/slide1/Slide1'),
                import('./Creatives/slide4/Slide4'),
                import('./Creatives/slide3/Slide3'),

                import('./Creatives/slide2/Slide2'),
                // import('./Creatives/slide5/Slide5'),
            ],
        },
        refill: {
            title: t('services_block.name_services_btn.btn6'),
            blocks: [
                import('./Refill/slide1/Slide1'),
            ],
        },
    };

    useEffect(() => {
        switch (page) {
            case 'creative':
                setlLabelFirstButton(t('services_block.creatives.creatives_name_btn.bt5'));
                break;
            case 'research':
                setlLabelFirstButton(t('services_block.researches.researches_name_btn.bt5'));
                break;
            case 'performance':
                setlLabelFirstButton(t('services_block.performance.performance_name_btn.bt4'));
                break;
            case 'brandformance':
                setlLabelFirstButton(t('services_block.brandformance.brandformance_name_btn.bt5'));
                break;
            case 'refill':
                setlLabelFirstButton('refill');
                break;
            default:
                setlLabelFirstButton('');
                break;
        }
        const loadSlides = async () => {
            const loadedSlides = [];
            for (let i = 0; i < pages[page].blocks.length; i++) {
                const slide = await pages[page].blocks[i];
                loadedSlides.push(slide.default);
            }
            setSlides(loadedSlides);
        };
        loadSlides().then(() => setLoaded(true));
    }, [page]);

    const memoizedSlides = useMemo(() => slides, [slides]);

    return (
        <div
            style={{
                post: 'relative',
                overflow: 'hidden',
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
            }}
        >
            <div className={'slider'}>
                <SliderTabs pages={pages} page={page} setPage={setPage}></SliderTabs>
                {loaded && (
                    <SwiperService
                        slides={memoizedSlides}
                        isFirstTab={page === 'tradeView'}
                        labelFirst={labelFirstButton}
                    ></SwiperService>
                )}
            </div>
        </div>
    );
}
