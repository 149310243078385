import React from "react";
import Typography from "@mui/material/Typography";
import {Button, Box} from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, EffectCoverflow, Zoom } from "swiper";
import "swiper/scss";
import "swiper/scss/effect-coverflow";
import "swiper/scss/zoom";
import Slide1 from '../../../static/images/tiktok/tiktok-presentation-1.webp';
import Slide2 from '../../../static/images/tiktok/tiktok-presentation-2.webp';
import Slide3 from '../../../static/images/tiktok/tiktok-presentation-3.webp';
import Slide4 from '../../../static/images/tiktok/tiktok-presentation-4.webp';
import PresentationDoc from "../../../static/presentations/TT mediakit 2023 (1).pdf";

SwiperCore.use([ Autoplay, EffectCoverflow, Zoom]);
export const SwiperTikTok = () => {
    const downloadPresentation = () => {
        window.open(PresentationDoc, '_blank');
    };
    return (
        <section className='section-grey'>
            <div className='tiktok-presentation' >
                <Typography className='section-title'>
                    Рекламные форматы TikTok
                </Typography>
                <Swiper
                    autoplay={{
                        delay: 200000
                    }}
                    loop
                    effect="coverflow"
                    coverflowEffect={{
                        rotate: 0,
                        stretch: 0,
                        depth: 150,
                        modifier: 1,
                        slideShadows: false,
                        scale: 0.75
                    }}
                    slidesPerView={1.8}
                    centeredSlides
                    style={{ width: "100%" }}
                >
                    <SwiperSlide>
                        <img src={Slide1} alt="Slide 1" style={{ width: '100%', height: 'auto' }} />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={Slide2} alt="Slide 2" style={{ width: '100%', height: 'auto' }} />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={Slide3} alt="Slide 3" style={{ width: '100%', height: 'auto' }} />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={Slide4} alt="Slide 4" style={{ width: '100%', height: 'auto' }} />
                    </SwiperSlide>
                </Swiper>
                <Box sx={{padding: "0 24px"}}>
                    <Button className={'button-filled-tiktok'} onClick={downloadPresentation}>
                        Скачать Презентацию
                    </Button>
                </Box>
            </div>
        </section>
    );
}