import React, {useCallback, useState} from 'react';

import 'swiper/css/effect-coverflow';
import './SwiperCompanies.scss';

import Google from '../../../static/images/companies/Google.svg';
import Meta from '../../../static/images/companies/Meta.svg';
import GoogleEdu from '../../../static/images/companies/GoogleEdu.svg';
import GooglePartner from '../../../static/images/companies/GooglePartner.svg';
import TikTok from '../../../static/images/companies/TikTok.svg';
import YandexDirect from '../../../static/images/companies/YandexDirect.svg';

import CompanyCard from '../../Cards/CompanyCard/CompanyCard';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {EffectCoverflow} from 'swiper';
import SwiperAdaptiveTemplate from '../SwipersTemplate';
import {useTranslation} from "react-i18next";

export default function SwiperCompanies() {
    const { t, i18n } = useTranslation();
    const companies = [
        {
            name: t('slider_partner.Meta.title'),
            img: Meta,
            text: t('slider_partner.Meta.description'),
        },
        {
            name: t('slider_partner.GMP.title'),
            img: Google,
            text: t('slider_partner.GMP.description'),
        },
        {
            name: t('slider_partner.Google.title'),
            img: GooglePartner,
            text: t('slider_partner.Google.description'),
        },
        {
            name: t('slider_partner.TikTok.title'),
            img: TikTok,
            text: t('slider_partner.TikTok.description'),
        },
        {
            name: t('slider_partner.Yandex.title'),
            img: YandexDirect,
            text: t('slider_partner.Yandex.description'),
        },
        {
            name: t('slider_partner.Google&Yandex.title'),
            img: GoogleEdu,
            text: t('slider_partner.Google&Yandex.description'),
        },
    ];
    const [swiperRef, setSwiperRef] = useState();
    const handlePrevious = useCallback(() => {
        swiperRef?.slidePrev();
    }, [swiperRef]);

    const handleNext = useCallback(() => {
        swiperRef?.slideNext();
    }, [swiperRef]);

    const generalProps = {
        onSwiper: setSwiperRef,
        grabCursor: true,
        loop: true,
        className: 'swiper-companies',
    };

    const mobileProps = {
        effect: 'coverflow',
        centeredSlides: true,
        slidesPerView: 'auto',
        slideToClickedSlide: true,
        coverflowEffect: {
            rotate: 0,
            stretch: -30,
            depth: 300,
            modifier: 3,
            slideShadows: false,
        },
        modules: [EffectCoverflow],
    };
    const desktopProps = {
        slidesPerView: 2,
        spaceBetween: 24,
    };

    return (
        <div className={'swiper-companies'}>
            <button onClick={handlePrevious}>
                <ArrowBackIosIcon sx={{height: 24}} />
            </button>
            <SwiperAdaptiveTemplate
                mp={{...generalProps, ...mobileProps}}
                dp={{...generalProps, ...desktopProps}}
                data={companies}
                bp={1000}
                rc={(card) => <CompanyCard card={card} />}
            />
            <button onClick={handleNext}>
                <ArrowForwardIosIcon sx={{height: 24}} />
            </button>
        </div>
    );
}
