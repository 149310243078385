import React, {useCallback, useEffect, useState} from 'react';
import './InfiniteLoop.scss';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIosNew';
import SwiperAdaptiveTemplate from '../Swiper/SwipersTemplate';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {Autoplay, EffectCoverflow} from 'swiper';

export default function InfiniteLoop() {
    function importAll(r) {
        return r.keys().map(r);
    }

    const [images, setImages] = useState([]);

    useEffect(() => {
        setImages(
            importAll(require.context('../../static/images/logos', false, /\.(png|jpe?g|svg)$/))
        );
    }, []);

    const [swiperRef, setSwiperRef] = useState();
    const handlePrevious = useCallback(() => {
        swiperRef?.slidePrev();
    }, [swiperRef]);

    const handleNext = useCallback(() => {
        swiperRef?.slideNext();
    }, [swiperRef]);

    const generalProps = {
        onSwiper: setSwiperRef,
        effect: 'coverflow',
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        slideToClickedSlide: true,
        autoplay: {
            delay: 1000,
            disableOnInteraction: false,
        },
        loop: true,
        modules: [EffectCoverflow, Autoplay],
        className: 'mySwiper',
    };
    const mobileProps = {
        coverflowEffect: {
            rotate: 0,
            stretch: -40,
            depth: 300,
            modifier: 2,
            slideShadows: false,
        },
    };

    const desktopProps = {
        coverflowEffect: {
            rotate: 0,
            stretch: -30,
            depth: 0,
            modifier: 1,
            slideShadows: false,
        },
        spaceBetween: 12,
    };
    return (
        <div className={'swiper-logos'}>
            {images.length > 0 ? (
                <>
                    <button onClick={handlePrevious}>
                        <ArrowBackIosIcon sx={{height: 24}} />
                    </button>
                    <SwiperAdaptiveTemplate
                        mp={{...generalProps, ...mobileProps}}
                        dp={{...generalProps, ...desktopProps}}
                        data={images}
                        rc={(img) => (
                            <div className={'mobile_bg'}>
                                <img src={img} alt={'mobile background'}/>
                            </div>
                        )}
                    />
                    <button onClick={handleNext}>
                        <ArrowForwardIosIcon sx={{height: 24}} />
                    </button>
                </>
            ) : null}
            {/*<div*/}
            {/*    className={'infinite-loop--anim-element'}*/}
            {/*    style={{animationDuration: `${images.length}s`}}*/}
            {/*>*/}
            {/*    <div>*/}
            {/*        {images.map((img, index) => (*/}
            {/*            <img src={img} key={index}></img>*/}
            {/*        ))}*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    );
}
