import SwiperButtons from '../../Swiper/SwiperButtons';
import React, {useEffect, useMemo, useState} from 'react';
import styles from './index.module.scss';
import Layout from './Components/Layout';
import {useTranslation} from "react-i18next";



export default function ServiceMobile() {
    const { t, i18n } = useTranslation();
    const [formedData, setFormedData] = useState([]);

    const buttons = [
        {name: 'tradeView', label: t('services_block.name_services_btn.btn1')},
        {name: 'brandformance', label: t('services_block.name_services_btn.btn2')},
        {name: 'performance', label: t('services_block.name_services_btn.btn3')},
        {name: 'research', label: t('services_block.name_services_btn.btn4')},
        {name: 'creative', label: t('services_block.name_services_btn.btn5')},
        {name: 'refill', label: t('services_block.name_services_btn.btn6')},
    ];

    const [page, setPage] = useState(buttons[0].name);


    const slides = useMemo(() => {
        return {
            tradeView: [
                {
                    content: import('./tradeView/FirstPage/index'),
                },
                {
                    title: t("services_block.digital_market_overview.p2.title"),
                    content: import('./tradeView/Slide2/index'),
                },
                {
                    title: t("services_block.digital_market_overview.p3.title"),
                    content: import('./tradeView/Slide3/index'),
                },
                {
                    title: t("services_block.digital_market_overview.p4.title"),
                    content: import('./tradeView/Slide4/index'),
                },
                {
                    title: t("services_block.digital_market_overview.p5.title"),
                    content: import('./tradeView/Slide5/index'),
                },
                {
                    title: t("services_block.digital_market_overview.p6.title"),
                    content: import('./tradeView/Slide6/index'),
                },
            ],
            brandformance: [
                {
                    content: import('./Brandformance/FirstPage/index'),
                },
                {
                    title: t("services_block.brandformance.case.case1.title"),
                    content: import('./Brandformance/Slide2/index'),
                },
                {
                    title: t("services_block.brandformance.case.case2.title"),
                    content: import('./Brandformance/Slide3/index'),
                },
            ],
            performance: [
                {
                    content: import('./Performance/FirstPage/index'),
                },
                {
                    title: t('services_block.performance.case.case1.title'),
                    content: import('./Performance/Slide2/index'),
                },
                {
                    title: t('services_block.performance.case.case2.title'),
                    content: import('./Performance/Slide3/index'),
                },
                {
                    title: t('services_block.performance.case.case3.title'),
                    content: import('./Performance/Slide4/index'),
                },
                {
                    title: t('services_block.performance.case.case4.title'),
                    content: import('./Performance/Slide5/index'),
                },
            ],
            research: [
                {
                    content: import('./Research/FirstPage/index'),
                },
                {
                    title: t('services_block.researches.researches_subtitles_list.title'),
                    content: import('./Research/Slide2/index'),
                },
                {
                    title: t('services_block.researches.cards_examples.card5.description'),
                    content: import('./Research/Slide3/index'),
                },
                {
                    title: t('services_block.researches.researches_name_btn.bt5'),
                    content: import('./Research/Slide4/index'),
                },
            ],
            creative: [
                {
                    content: import('./Creatives/FirstPage/index'),
                },
                {
                    title: t('services_block.creatives.examples_cards.cards3.title'),
                    content: import('./Creatives/Slide2/index'),
                },
                {
                    title: t('services_block.creatives.examples_cards.cards2.title'),
                    content: import('./Creatives/Slide3/index'),
                },
                {
                    title: t('services_block.creatives.examples_cards.cards1.mobile_title'),
                    content: import('./Creatives/Slide4/index'),
                },
            ],
            refill: [
                {
                    content: import('./Refill/FirstPage/index'),
                },
                // {
                //     content: import('./Refill/Slide2/index'),
                // },

            ],
        };
    }, []);

    useEffect(() => {
        const loadSlides = async () => {
            const data = slides[page];
            const loadedSlides = [];
            for (let i = 0; i < data.length; i++) {
                const slide = await data[i].content;
                if (data[i].title) {
                    loadedSlides.push({title: data[i].title, content: slide.default});
                } else {
                    loadedSlides.push({content: slide.default});
                }
            }
            setFormedData(loadedSlides);
        };
        loadSlides();
    }, [page]);
    return (
        <div className={styles.mobile_service}>
            <SwiperButtons setActiveIndex={setPage} data={buttons}></SwiperButtons>
            <Layout data={formedData} page={page} key={page}></Layout>
        </div>
    );
}
