import './FullScreenPage.scss';
import BackgroundVideo from '../../../static/video/background.mp4';
import TDS_logo from '../../../static/images/TDS_biglogo.png';
import Button from '@mui/material/Button';
import CustomModal from '../../../Components/Modal';
import {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import TenderForm from '../../../Components/Forms/TenderForm';

export default function FullScreenPage({active}) {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [isScrolled, setIsScrolled] = useState(false);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const handleScroll = () => {
            // Calculate the scroll position relative to the document height
            const scrollY = window.scrollY;
            const documentHeight = document.documentElement.scrollHeight;
            const windowHeight = window.innerHeight;
            const scrollPercentage = (scrollY / (documentHeight - windowHeight)) * 100;

            // Check if the scroll percentage is greater than or equal to 50%
            if (scrollPercentage >= 50) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        // Attach the scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <section className={'fullscreen'} style={{opacity: isScrolled ? 0 : 1}}>
            <video autoPlay muted loop id="backgroundVideo">
                <source src={BackgroundVideo} type="video/mp4" />
            </video>
            <div className={'content'}>
                <img
                    src={TDS_logo}
                    alt={'TDS Media Logo'}
                    className={`logo ${active ? 'logo-active' : null}`}
                />
                <h1
                    className={'font-48 w300'}
                    style={{color: 'white', textAlign: 'center', textTransform: 'uppercase'}}
                >
                    {t("home_block.first_title")} <br className={'mobile-br'} />
                    {t("home_block.second_title")}
                    <br />{t("home_block.third_title")}
                </h1>
                <Button className={'button-filled'} onClick={handleOpen}>
                    {t("home_block.home_btn")}
                </Button>
            </div>

            <CustomModal open={open} setOpen={setOpen}>
                <TenderForm close={handleClose} />
            </CustomModal>
        </section>
    );
}
