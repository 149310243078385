import './PersonCard.scss';

export default function PersonCard({person}) {
    return (
        <div className={'person-card'}>
            <img alt={"person avatar"} src={person?.avatar} />

            <div className={'person-card--info'}>
                <h1 className={'font-10 w600'}>{person?.name}</h1>
                <h2 className={'font-10 w400'} style={{lineHeight: '12px'}}>
                    {person?.post}
                </h2>
            </div>
        </div>
    );
}
