




import React from "react";

export const Visa = ({}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="45" height="16" viewBox="0 0 45 16" fill="none">
        <path d="M19.6715 14.9924H16.1276L18.3442 1.05273H21.888L19.6715 14.9924Z" fill="white"/>
        <path d="M32.5189 1.39352C31.8199 1.11147 30.7111 0.800049 29.3402 0.800049C25.8404 0.800049 23.3759 2.69808 23.3608 5.41174C23.3317 7.41387 25.1253 8.52591 26.4668 9.19348C27.8379 9.87563 28.304 10.3209 28.304 10.9289C28.29 11.8628 27.196 12.2932 26.1757 12.2932C24.7608 12.2932 24.0026 12.0713 22.8505 11.5518L22.3838 11.329L21.8879 14.4582C22.7191 14.8433 24.2505 15.1851 25.8404 15.2C29.559 15.2 31.9799 13.3313 32.0086 10.4395C32.0228 8.8527 31.0757 7.63677 29.0338 6.6432C27.7943 6.00541 27.0352 5.57532 27.0352 4.92274C27.0497 4.32948 27.6772 3.72181 29.0765 3.72181C30.2286 3.69207 31.0751 3.9737 31.7163 4.25555L32.0369 4.40359L32.5189 1.39352Z" fill="white"/>
        <path d="M37.2292 10.054C37.5211 9.25322 38.6441 6.15387 38.6441 6.15387C38.6294 6.18362 38.9354 5.33826 39.1104 4.81931L39.358 6.02041C39.358 6.02041 40.0294 9.35711 40.1751 10.054C39.6209 10.054 37.9292 10.054 37.2292 10.054ZM41.6036 1.05273H38.8625C38.0172 1.05273 37.3749 1.30462 37.0102 2.20934L31.7461 14.9921H35.4647C35.4647 14.9921 36.0769 13.2717 36.2085 12.9011C36.6164 12.9011 40.2338 12.9011 40.7587 12.9011C40.8605 13.3905 41.1814 14.9921 41.1814 14.9921H44.4631L41.6036 1.05273Z" fill="white"/>
        <path d="M13.1676 1.05273L9.6969 10.5582L9.31766 8.63038C8.67597 6.40593 6.66358 3.98912 4.41785 2.78744L7.59691 14.9776H11.3446L16.9151 1.05273H13.1676Z" fill="white"/>
        <path d="M6.47397 1.05273H0.772078L0.713745 1.33441C5.16157 2.49118 8.1073 5.27953 9.31757 8.63097L8.07806 2.22451C7.87397 1.3342 7.24686 1.08214 6.47397 1.05273Z" fill="white"/>
    </svg>
);

